

.login {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	position: relative;

	h1 {
		font-size: 48px;
		line-height: 1;
	}
}

.login-form {
	padding: rh(75) rs(40);
	background-color: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(10px);
	max-width: 475px;
	border-radius: 6px;

	display: flex;
	flex-direction: column;
	
	input {
		margin: rh(55) 0;
		--webkit-appearance: none;
		outline: none;
		border: none;
		border-bottom: 1px solid #fff;
		padding: 5px 0;
		background: transparent;
		color: #fff;
	}

	.button {
		align-self: center;
	}

	&.appear,
	&.enter {
		opacity: 0;
		transform: translateY(50px);

		&-active,
		&-done {
			opacity: 1;
			transform: translateY(0);
			transition: opacity 0.6s $easeOutCubic 0.6s, transform 0.6s $easeOutCubic 0.6s;
		}
	}
}