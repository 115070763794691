.home {
	width: 100%;
	height: 100%;
	padding: rh(250) rs(130) 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	.bg-video {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: -1;
	}

	h1 {
		font-weight: 700;
		font-size: clamp(3.5rem, rs(90), 5.5rem);
		line-height: 1;
		margin-bottom: 0.5em;

		span {
			display: block;
			font-weight: 500;
			font-size: clamp(1.5rem, fs(42), 2.6rem);
		}

		&.no-arrows {
			margin-bottom: 1em;
		}
	}

	.arrows {
		align-self: flex-end;
		display: flex;
		gap: 15px;
		margin-bottom: rh(40);

		.arrow {
			background-color: transparent;
			width: rs(70);
			aspect-ratio: 1 / 1;
			border-radius: 50%;
			border: 2px solid #242A31;
			display: flex;
			fill: #fff;
	
			svg {
				margin: auto;
			}
		}
	}

	.demo-list-wrapper {
		width: 100vw;
		overflow-x: scroll;
		margin: 0 rs(-130);
	}

	.demo-list {
		display: flex;
		gap: 20px;
		padding: 0 rs(130);
		width: fit-content;
	}

	.demo-link {
		flex: 0 0 rs(400);
		width: rs(400);
		height: rs(400);
		background-color: #1E1F21;
		position: relative;
		border-radius: 6px;
		overflow: hidden;
		line-height: 1.1;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.7;
			position: relative;
			transform-origin: center center;
			transition: transform 1.2s ease;
		}

		span {
			position: absolute;
				bottom: rs(15);
				left: rs(25);

			font-size: clamp(1.5rem, rs(40), 2.5rem);
			font-weight: 700;
			color: #fff;
			text-transform: uppercase;

			transition: transform 0.9s ease;
		}

		&:hover {
			img {
				transform: scale(1.1);
			}

			span {
				transform: translateY(-10px);
			}
		}
	}
}