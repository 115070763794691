%reset_button {
	padding: 0;
	background-color: transparent;
	border: 0;
}

%base_button {
	position: relative;
	display: inline-block;

	@extend %text_button;
	font-weight: $font-weight-bold;
	padding: 17px 20px;
	border-radius: 6px;
	
	text-align: center;

	cursor: pointer;
	
	transition: background-color 0.3s ease, transform 0.3s ease;
	transform: scale(1);

	span {
		color: inherit;
	}

	&:hover {
		color: $color-text-secondary;
		transform: scale(1.05);
	}

}


[class^=button] {
	@extend %base_button;
}