html {
	font-size: 100%;
	background-size: 100% auto;
	background-color: var(--color-background-main);
}

body {
	position: relative;
	-webkit-overflow-scrolling: touch;
	background-color: var(--color-background-main);
	z-index: 1;

	.background {
		position: absolute;
			top: 0;
			left: 0;

		width: 100%;
		height: 100%;
		z-index: -5;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	header {
		position: absolute;
			top: 0;
			left: 0;
			border-bottom: 1px solid rgba(#242A31, 0.95);

		width: 100%;
		height: clamp(80px, rh(100), 100px);

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;
	}

	span {
		cursor: pointer;
		transition: opacity 0.3s;
		&:hover {
			opacity: 0.8;
		}
	}
}

#root {
	width: 100%;
	height: 100vh;
}

.main-content {
	position: relative;
	z-index: 1;
}

img {
	max-width: 100%;
	height: auto;
}

*, ::before, ::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:focus {
	text-decoration: none;
}

button {
	border: none;
}

.container, .container-fluid {
	width: calc(100% - var(--container-gutter));
}

.section-block {
	margin-top: var(--section-spacing-top);
	margin-bottom: var(--section-spacing-bottom);

	&.with-background {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: var(--section-spacing-top);
		padding-bottom: var(--section-spacing-bottom);
	}
}



*::-webkit-scrollbar {
	width: 0px;
	height: 0;
}

*::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 0;
}

*::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-radius: 0;
}


.page.enter,
.page.appear {
	*::-webkit-scrollbar,
	*::-webkit-scrollbar-track,
	*::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
}

pre {
	white-space: pre-wrap;
}