
// Website variables;
$grid-gutter-width: 30px !default;
$grid-gutter-widths: (
	xs: $grid-gutter-width,
	sm: $grid-gutter-width,
	md: $grid-gutter-width,
	lg: $grid-gutter-width,
	xl: $grid-gutter-width
) !default;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
	xs: 0,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1500px,
	xxl: 1700px,
);

$container-gutter: calc((240 * 100 / 1920) * 1vw);
$container-gutter-mobile: 10px;

$container-max-widths: (
  xxl: none,
);

$max-width-viewport: 1920;
$menuzindex: 10;
$MenuEasing: $easeInspiir;

//===================================================================
// Colors
//===================================================================

$black: #000;
$blackrgb: 0,0,0;
$white: #fff;
$white96: #f6f6f6;

$gray26: #414143;
$gray26rgb: 65,65,67;

$gray10: #17181A;
$gray27: #2E3846;

$color-text-primary: $white;
$color-text-secondary: $black;

$color-accent-primary: var(--color-accent-primary, #C9082A);
$color-accent-primary-rgb: var(--color-accent-primary-rgb, 201,8,42);
$color-accent-secondary: $white;

$color-background-main: $gray10;
$color-background-primary: $white96;
$color-background-secondary: $black;

$section-spacing: 100px;
$section-spacing-mobile: 50px;

$menuHeight: 70px;
$menuHeightMobile: 50px;

$MenuEasing: $easeInspiir;

//===================================================================
// Fonts
//===================================================================

$font-family-title: 'Plus Jakarta Sans', sans-serif;
$font-family-text: 'Plus Jakarta Sans', sans-serif;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-size-title1-xl: 72px;
$font-size-title1-xs: 32px;
$font-size-title1: clamp(#{fs($font-size-title1-xs)}, calc(#{strip-unit($font-size-title1-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-title1-xl)});
$line-height-title1: calc(82/#{strip-unit($font-size-title1-xl)});
$letter-spacing-title1: normal;

$font-size-title2-xl: 60px;
$font-size-title2-xs: 30px;
$font-size-title2: clamp(#{fs($font-size-title2-xs)}, calc(#{strip-unit($font-size-title2-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-title2-xl)});
$line-height-title2: calc(70/#{strip-unit($font-size-title2-xl)});
$letter-spacing-title2: normal;

$font-size-title3-xl: 46px;
$font-size-title3-xs: 26px;
$font-size-title3: clamp(#{fs($font-size-title3-xs)}, calc(#{strip-unit($font-size-title3-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-title3-xl)});
$line-height-title3: calc(56/#{strip-unit($font-size-title3-xl)});
$letter-spacing-title3: normal;

$font-size-title4-xl: 30px;
$font-size-title4-xs: 22px;
$font-size-title4: clamp(#{fs($font-size-title4-xs)}, calc(#{strip-unit($font-size-title4-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-title4-xl)});
$line-height-title4: calc(40/#{strip-unit($font-size-title4-xl)});
$letter-spacing-title4: normal;

$font-size-title5-xl: 26px;
$font-size-title5-xs: 20px;
$font-size-title5: clamp(#{fs($font-size-title5-xs)}, calc(#{strip-unit($font-size-title5-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-title5-xl)});
$line-height-title5: calc(36/#{strip-unit($font-size-title5-xl)});
$letter-spacing-title5: normal;

$font-size-upper-title-xl: 20px;
$font-size-upper-title-xs: 16px;
$font-size-upper-title: clamp(#{fs($font-size-upper-title-xs)}, calc(#{strip-unit($font-size-upper-title-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-upper-title-xl)});
$line-height-upper-title: calc(28/#{strip-unit($font-size-upper-title-xl)});
$letter-spacing-upper-title: normal;

$font-size-subtitle-xl: 26px;
$font-size-subtitle-xs: 20px;
$font-size-subtitle: clamp(#{fs($font-size-subtitle-xs)}, calc(#{strip-unit($font-size-subtitle-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-subtitle-xl)});
$line-height-subtitle: calc(36/#{strip-unit($font-size-subtitle-xl)});
$letter-spacing-subtitle: normal;

$font-size-text-xl: 18px;
$font-size-text-xs: 16px;
$font-size-text: clamp(#{fs($font-size-text-xs)}, calc(#{strip-unit($font-size-text-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-text-xl)});
$line-height-text: calc(32/#{strip-unit($font-size-text-xl)});
$letter-spacing-text: normal;

$font-size-button-xl: 16px;
$font-size-button-xs: 16px;
$font-size-button: clamp(#{fs($font-size-button-xs)}, calc(#{strip-unit($font-size-button-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-button-xl)});
$line-height-button: calc(21/#{strip-unit($font-size-button-xl)});
$letter-spacing-button: normal;

$font-size-form-xl: 14px;
$font-size-form-xs: 14px;
$font-size-form: clamp(#{fs($font-size-form-xs)}, calc(#{strip-unit($font-size-form-xl)}/#{$max-width-viewport} * 100vw), #{fs($font-size-form-xl)});
$line-height-form: calc(21/#{strip-unit($font-size-form-xl)});
$letter-spacing-form: normal;

/* <small> tag font size */
$font-size-small-tag: 12px;

//===================================================================
// Forms
//===================================================================

$form-wrapper-background: $color-background-primary;
$form-group-spacing: 25px;
$form-checkbox-radio-group-spacing: 18px;

$form-label-font-family: $font-family-title;
$form-label-font-size: 18px;
$form-label-animated-font-size: 10px;
$form-label-animated-weight: $font-weight-normal;
$form-label-font-weight: $font-weight-medium;
$form-label-color: lighten($black, 50);
$form-label-spacing: 8px;

$form-placeholder-color: lighten($black, 50);

$form-control-color: $color-accent-primary;
$form-control-font-size: 18px;
$form-control-border-width: 1px;
$form-control-border-color: $black;
$form-control-border-radius: 0px;
$form-control-bgcolor: $white;
$form-control-padding-x: 16px;
$form-control-padding: 12px $form-control-padding-x 12px;
$form-control-bgcolor-focus: $white;
$form-control-border-color-error: red;

$form-select-padding-right: calc(#{$form-control-padding-x} + 30px);
$form-select-icon-color: $black;

$form-textarea-padding-bottom: 30px;

$form-checkbox-size: 20px;
$form-checkbox-bgcolor: $white;
$form-checkbox-color: $white;
$form-checkbox-font-size: 16px;
$form-checkbox-transition-duration: 0.3s;
$form-checkbox-bgcolor-checked: $color-accent-primary;
$form-checkbox-border-color: $color-text-primary;
$form-checkbox-border-color-checked: $color-accent-primary;

$form-footer-spacing: 40px;

$form-error-color: red;
$form-error-border-color: red;
$form-error-font-size: 14px;

//===================================================================
// Dialog
//===================================================================
$dialog-backdrop-bgcolor: $black;
$dialog-backdrop-opacity: 0.8;
$dialog-backdrop-opacity-sm: 0.95;
$dialog-transition-duration: 0.4s;
$dialog-zindex: 999999;
$dialog-bgcolor: $white;
$dialog-text-color: $black;
$dialog-font-size: 14px;
$dialog-content-padding: 40px;

$dialog-close-position-top: -20px;
$dialog-close-size: 50px;
$dialog-close-color: $white;
$dialog-close-fontsize: 30px;
